import React from 'react';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import Rating from '@atoms/Rating';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import CardSlider from '@components/CardSlider/CardSlider';
import './QuoteAndUserReview.scss';

const QuoteAndUserReview = () => {
    const { t } = useTranslation();

    const USER_REVIEWS = [
        {
            text: t('testimonials.secondary.user.review.one'),
        },
        {
            text: t('testimonials.secondary.user.review.two'),
        },
        {
            text: t('testimonials.secondary.user.review.three'),
        },
        {
            text: t('testimonials.secondary.user.review.four'),
        },
    ];

    return (
        <Section theme="dark" spacingBottom="l" spacingTop="s">
            <Section container="large">
                <div className="QuoteBlock">
                    <Title tag="p" size="xl" theme="white" align="center">
                        “{t('testimonials.hero.quote.claim')}”
                    </Title>
                    <Title size="m" theme="white" align="center">
                        {t('testimonials.hero.quote.author')}
                    </Title>
                </div>
            </Section>
            <Section spacingTop="m">
                <div className="g_clearfix g_center UserReviewBlock">
                    <CardSlider
                        sliderOption={{
                            autoplay: true,
                            allowSlidePrev: true,
                            allowSlideNext: true,
                            centeredSlides: true,
                            initialSlide: 0,
                            slidesPerView: 1.3,
                            breakpoints: {
                                600: {
                                    slidesPerView: 1.99,
                                },
                                1001: {
                                    centeredSlides: false,
                                    slidesPerView: 4,
                                    allowSlidePrev: false,
                                    allowSlideNext: false,
                                },
                            },
                        }}
                    >
                        {USER_REVIEWS.map((item: any, index: number) => (
                            <SwiperSlide key={index}>
                                <div className="UserReviewBlock--item">
                                    <Rating />
                                    <Text size="m" theme="white" align="center">
                                        “{item.text}”
                                    </Text>
                                </div>
                            </SwiperSlide>
                        ))}
                    </CardSlider>
                </div>
            </Section>
        </Section>
    );
};

export default QuoteAndUserReview;
