import React from 'react';

import './CardGrid.scss';

CardGrid.defaultProps = {
    size: '3',
};

export default function CardGrid({ children, size }) {
    let mod_class = ['CardGrid', size !== undefined ? 'size-' + size : ''];
    mod_class = mod_class.join(' ').replace(/\s{2,}/g, ' ');

    return <div className={mod_class}>{children}</div>;
}
