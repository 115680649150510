import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { FiLogOut } from 'react-icons/fi';
import { AiOutlineLock } from 'react-icons/ai';
import Module from '@atoms/Module/Module';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import IconText from '../IconText/IconText';
import Button from '@atoms/Button/Button';
import TitleGroup from '@atoms/TitleGroup/TitleGroup';
import './PrivateAndSecureShortInfo.scss';
import { useLocale } from '@hooks';

const PrivateAndSecureShortInfo = () => {
    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();

    return (
        <div className="PrivateAndSecureShortInfo">
            <div className="PrivateAndSecureShortInfo-leftContent">
                <Module radius="giant" padding="l">
                    <Section spacingBottom="s">
                        <Text size="m" align="center">
                            {t('privacy.card.title')}
                        </Text>
                        <Title size="xl" align="center">
                            {t('privacy.card.claim')}
                        </Title>
                        <Text size="s" align="center">
                            {t('privacy.card.subtitle')}
                        </Text>
                    </Section>

                    <div className="PrivateAndSecureShortInfo--list">
                        <div className="PrivateAndSecureShortInfo--inner">
                            {[
                                {
                                    text: t('privacy.card.key.point.one'),
                                    icon: <AiOutlineLock />,
                                },
                                {
                                    icon: <FiLogOut />,
                                    text: t('privacy.card.key.point.two'),
                                },
                            ].map((item: any) => (
                                <IconText
                                    icon={
                                        <Text theme="light" size="l" tag="span">
                                            {item.icon}
                                        </Text>
                                    }
                                    text={
                                        <Title size="s" noMargin tag="span">
                                            {item.text}
                                        </Title>
                                    }
                                />
                            ))}
                        </div>
                    </div>
                    <Section spacingTop="s">
                        <a
                            href={getAppLinkWithCurrentLang(
                                process.env.GATSBY_START_FULL_FUNNEL_URL
                            )}
                        >
                            <Button
                                variant="naked"
                                iconArrow
                                label={t('button.get.started')}
                                align="center"
                            />
                        </a>
                    </Section>
                </Module>
            </div>

            <div className="PrivateAndSecureShortInfo-rightContent">
                <div className="PrivateAndSecureShortInfo--card">
                    <TitleGroup
                        title={t('privacy.section.title')}
                        subtitle={t('privacy.section.subtitle')}
                    />
                </div>
            </div>
        </div>
    );
};

export default PrivateAndSecureShortInfo;
