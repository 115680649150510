import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillPlayCircle } from 'react-icons/ai';
import Button from '@atoms/Button/Button';
import { GlobalContext } from '@context/GlobalContext';

interface Props {
    onButtonClick: VoidFunction;
}

const HowDoesItWorkCTA = ({ onButtonClick }: Props) => {
    const { t } = useTranslation();
    const [globalState, setGlobalState] = useContext(GlobalContext);
    const handleOpenVideo = () => {
        onButtonClick();
        setGlobalState((prevData: any) => ({
            ...prevData,
            videoModalIsOpen: !globalState.videoModalIsOpen,
        }));
    };
    return (
        <Button
            variant="naked"
            label={t('button.why.it.works')}
            icon={<AiFillPlayCircle />}
            onClick={handleOpenVideo}
        />
    );
};

export default HowDoesItWorkCTA;
