import React from 'react';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Avatar from '../Avatar/Avatar';
import './ProviderStatementSlider.scss';

interface Props {
    image: string;
    audio?: string | undefined;
    quote: string;
    providerName: string;
    providerSubtitle: string;
}

const ProviderStatementSlide: React.FC<Props> = ({
    image,
    audio,
    quote,
    providerName,
    providerSubtitle,
}) => {
    const className = ['ProviderStatementSlide']
        .join(' ')
        .replace(/\s{2,}/g, ' ');

    return (
        <div className={className}>
            <Section spacingBottom="xxs" spacingTop="s">
                <div className="ProviderStatementSlide--image">
                    <Avatar
                        align="center"
                        src={image}
                        size="l"
                        audioSrc={audio && audio}
                    />
                </div>

                <Divider invisible spacing="m" />

                <Title font="alt" size="ul" align="center">
                    "{quote}"
                </Title>

                <Text align="center" size="s" noMargin>
                    {providerName}
                </Text>
                <Text align="center" size="s">
                    {providerSubtitle}
                </Text>
            </Section>
        </div>
    );
};

export default ProviderStatementSlide;
