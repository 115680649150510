import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@atoms/Button/Button';
import { useLocale } from '@hooks';
import CardGrid from '../CardGrid/CardGrid';
import './CoachVsTherapy.scss';
import CoachVsTherapyCard from './CoachVsTherapyCard';

export default function CoachVsTherapy() {
    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();
    const mod_class = ['CoachVsTherapy'].join(' ');

    const static_images = useStaticQuery(
        graphql`
            query {
                coachIllustration: file(
                    relativePath: { eq: "global/illustration-for-coach.png" }
                ) {
                    childImageSharp {
                        fluid(maxWidth: 526, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                therapyIllustration: file(
                    relativePath: { eq: "global/illustration-for-therapy.png" }
                ) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 562) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    return (
        <div className={mod_class}>
            <CardGrid size="2">
                {/* Coach */}
                <CoachVsTherapyCard
                    title={t('coaching.vs.therapy.coaching.title')}
                    subtitle={t('coaching.vs.therapy.coaching.subtitle')}
                    image={
                        <Img
                            fluid={
                                static_images.coachIllustration.childImageSharp
                                    .fluid
                            }
                            alt="Illustration - Psychologisches Coaching"
                            imgStyle={{ objectFit: 'contain' }}
                        />
                    }
                    cta={
                        <a
                            href={getAppLinkWithCurrentLang(
                                process.env.GATSBY_START_COACHING_FUNNEL_URL
                            )}
                        >
                            <Button
                                label={t(
                                    'coaching.vs.therapy.coaching.cta.label'
                                )}
                            />
                        </a>
                    }
                >
                    <ul>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.coaching.reason.one'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.coaching.reason.two'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.coaching.reason.three'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.coaching.reason.four'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.coaching.reason.five'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.coaching.reason.six'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                    </ul>
                </CoachVsTherapyCard>

                {/* Therapy */}
                <CoachVsTherapyCard
                    title={t('coaching.vs.therapy.therapy.title')}
                    subtitle={t('coaching.vs.therapy.therapy.subtitle')}
                    image={
                        <Img
                            fluid={
                                static_images.therapyIllustration
                                    .childImageSharp.fluid
                            }
                            alt="Illustration - Psychotherapie"
                            imgStyle={{ objectFit: 'contain' }}
                        />
                    }
                    cta={
                        <a
                            href={getAppLinkWithCurrentLang(
                                process.env.GATSBY_START_THERAPY_FUNNEL_URL
                            )}
                        >
                            <Button
                                label={t(
                                    'coaching.vs.therapy.therapy.cta.label'
                                )}
                            />
                        </a>
                    }
                >
                    <ul>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.therapy.reason.one'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.therapy.reason.two'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.therapy.reason.three'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.therapy.reason.four'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey={
                                    'coaching.vs.therapy.therapy.reason.five'
                                }
                                components={{ strong: <strong /> }}
                            />
                        </li>
                    </ul>
                </CoachVsTherapyCard>
            </CardGrid>
        </div>
    );
}
