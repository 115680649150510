import React from 'react';
import { Trans } from 'react-i18next';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Title from '@atoms/Title/Title';
import { useLocale } from '@hooks';
import ProviderStatementSlider from '../ProviderStatementSlider';
import './ProviderStatementsWithTitle.scss';

interface Props {
    ctaLabel?: string;
    providerType: 'coach' | 'therapist';
    rightContent?: React.ReactNode;
}

const ProviderStatementsWithTitle: React.FC<Props> = ({
    ctaLabel,
    providerType,
    rightContent,
}) => {
    const className = ['ProviderStatementsWithTitle']
        .join(' ')
        .replace(/\s{2,}/g, ' ');
    const { getAppLinkWithCurrentLang } = useLocale();
    return (
        <div className={className}>
            <div className="ProviderStatementsWithTitle--slider">
                <ProviderStatementSlider providerType={providerType} />
            </div>
            <div className="ProviderStatementsWithTitle--title">
                {rightContent ? (
                    <>{rightContent}</>
                ) : (
                    <>
                        <Title size="xxl" theme="grey">
                            <Trans
                                i18nKey={'provider.statement.section.title.one'}
                                components={{ span: <span /> }}
                            />
                        </Title>
                        <Title size="xxl" theme="grey" noMargin>
                            <Trans
                                i18nKey={'provider.statement.section.title.two'}
                                components={{ span: <span /> }}
                            />
                        </Title>
                    </>
                )}

                <Divider invisible spacing="m" />
                {ctaLabel && (
                    <div className="g_hide_mobile">
                        <a
                            href={getAppLinkWithCurrentLang(
                                process.env.GATSBY_START_FULL_FUNNEL_URL
                            )}
                        >
                            <Button
                                theme="highlighted"
                                label={ctaLabel}
                                isMobileFullsize
                            />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProviderStatementsWithTitle;
