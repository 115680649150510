import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import React, { useContext } from 'react';
import { GrFormClose } from 'react-icons/gr';
import { GlobalContext } from '@context/GlobalContext';
import './VideoOverlay.scss';

interface Props {
    videoSrc: string;
}

const VideoOverlay: React.FC<Props> = ({
    videoSrc = 'https://www.youtube.com/embed/gQ17qMrjV9c',
}) => {
    const [globalState, setGlobalState] = useContext(GlobalContext);

    function closeModal() {
        setGlobalState((prevData: any) => ({
            ...prevData,
            videoModalIsOpen: false,
        }));
    }

    const className = ['VideoOverlay'].join(' ').replace(/\s{2,}/g, ' ');

    return (
        <>
            {globalState.videoModalIsOpen && (
                <Dialog onDismiss={() => closeModal()}>
                    <div className={className}>
                        <div
                            className="VideoOverlay--close"
                            onClick={() => closeModal()}
                        >
                            <GrFormClose size="40" />
                        </div>
                        <div className="VideoOverlay--inner">
                            <iframe
                                src={`${videoSrc}?modestbranding=1&rel=0&controls=1&autoplay=1`}
                                title="Aepsy Video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen="true"
                                frameborder="0"
                            ></iframe>
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    );
};

export default VideoOverlay;
