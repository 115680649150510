const FAQ_THERAPY_DE = [
    {
        isMarkdown: true,
        question: 'Wieso zuerst ein kostenloses 10min-Intro-Call buchen?',
        answer: `Bei Aepsy **bucht man vor dem Therapie Beginn ein kostenloses 10min-Intro** mit den Therapeuten:innen. Hier sind die Gründe wieso:

- Man **lernt die Therapeut:innen zuerst kennen** bevor man eine Therapie startet
- Zeitfenster für die Intros sind auch bei beschäftigten Therapeut:innen **rasch verfügbar**
- Problemstellungen werden besprochen und **sichergestellt, dass die Therapie passt**
- Finale **Terminfindung** wird gemeinsam am Telefon durchgeführt
- Letzte Fragen zur Abrechnung werden geklärt
- Beratung, ob **ein persönliches Treffen oder eine Online-Therapie** besser passt`,
    },
    {
        isMarkdown: true,
        question: 'Was wird im kostenlosen 10min-Intro-Call besprochen?',
        answer: `Die Gestaltung des 10min-Intros ist den Therapeut:innen überlassen. Grundsätzlich werden aber folgenden Aspekte besprochen:

- Bezug auf die **persönliche Herausforderung** (z.B. Lebensumtände)
- Besprechung der **Spezialgebiete** der Therapeut:innen
- Besprechung der **Vorgehensweise**
- Vereinbarung ob **Online-Therapie oder ein Treffen vor Ort**
- Fragen zur **Kostenübernahme**
- **Vereinbarung des gemeinsamen Termins**`,
    },
    {
        isMarkdown: true,
        question: 'Wie funktioniert die Therapeut:innen-Auswahl bei Aepsy?',
        answer: `1. Klicke auf *[Jetzt Auswahl starten](https://aepsy.com/buchen/f1/)* 
2. Beantworte einige Fragen zu deinen Bedürfnissen
3. Das Aepsy System evaluiert passende Therapeut:innen
4. Es wird eine handverlesene Auswahl und den "Aepsy match" präsentiert
5. Vergleiche die vorgeschlagenen Therapeut:innen
6. Treffe eine Auswahl und buche direkt deinen 10min-Intro-Call
7. Lerne Therapeut:innen kennen und wähle, wer zu dir passt
8. Bezahle unkompliziert online, Sitzung für Sitzung, so wie es für dich passt`,
    },
    {
        isMarkdown: true,
        question: 'Was ist mit "Online-Therapie" oder "vor Ort" gemeint? ',
        answer: `Unsere Therapeut:innen bieten Online-Beratungen und Besprechungen in Praxisräumlichkeiten an. Es ist zudem möglich die Methoden zu kombinieren. 

- Online-Beratungen können einfach von Zuhause aus durchgeführt werden. Die Effektivität von online Interventionen wurde wissenschaftlich bestätigt.
- Die Therapieräumlichkeiten sind auf dem Profil der Therapeut:innen aufgeführt. In der vorhergehenden Selektion kann die gewünschte Ortschaft ausgewählt werden.
- Es sind zudem auch Kombinationen möglich. Es besteht zum Beispiel die Möglichkeit die Therapeut:innen zuerst persönlich kennenzulernen und dann Gespräche online durchzuführen.

Starte deine Auswahl hier: *[Jetzt Auswahl starten](https://aepsy.com/buchen/f1)*`,
    },
    {
        isMarkdown: true,
        question: 'Was kosten die Therapiesitzungen?',
        answer: `10min-Intro-Call:

**Kostenlos**

Therapiesitzung:

**ab CHF 170 / h** 

Online-Therapiesitzung:

**ab CHF 160 / h**

- Aepsy vermittelt selbstständige Psychotherapeut:innen und agiert als Vermittlungsplattform
- Alle Therapeut:innen verfolgen eigene Preisstrategien
- Die Preise sind auf der Profilseite der Therapeut:innen aufgeführt

Hier kommst du zu den persönlichen Profilen der Therapeut:innen: [Jetzt Therapeut:in finden](http://www.aepsy.com/buchen/f1)`,
    },
    {
        isMarkdown: true,
        question: 'Wie läuft die Bezahlung ab?',
        answer: `- Die Intros werden kostenlos und ohne Angabe der Zahlungsweise gebucht / durchgeführt
- Folgesitzungen werden **unkompliziert online** **bezahlt**
- Du wählst den/die passende/n Therapeut:in und **bezahlst die Sitzungen vor zu**

Aktuell können die Zahlungen mit allen gängigen Kreditkarten sowie Twint durchgeführt werden.`,
    },
    {
        isMarkdown: true,
        question: 'Werden die Kosten von meiner Versicherung getragen?',
        answer: `- Psychotherapeut:innen, die bei Aepsy aufgeführt sind, bieten Plätze für Selbstzahler:innen / Teildeckung Zusatzversicherung an
- Es werden keine Diagnosen/Ärztliche Anordnungen vorausgesetzt (Modell für Grundversicherung)
- Die Kostenübernahmen variieren von Versicherung zu Versicherung
- Die Bezahlung finden online über Aepsy statt
- Der Beleg für die Abrechnung mit der Zusatzversicherung wird einfach als PDF generiert
        
Hier findest du zudem eine Liste der grössten Versicherungen mit den jeweiligen Zusatzversicherungen inklusive der Kostenübernahme: [Übernahme Therapiekosten Zusatzversicherung](https://aepsy.com/insurance-checker/) 
        
Hier erfährst du zudem wie das System zur Kostenübernahme für die Psychotherapie in der Schweiz funktioniert: [Delegierte Psychotherapie vs. selbständige Psychotherapie](https://aepsy.com/de/blog/versicherung-kosten-psychotherapie/)
        
Es werden keine Psychotherapien vermittelt, welche von der Grundversicherung übernommen werden (sog. angeordnete Psychotherapie) und ein ärztliche Anordnung voraussetzen.`,
    },
    {
        isMarkdown: true,
        question: 'Was macht die Therapeut:innen bei Aepsy speziell? ',
        answer: `- Aepsy interviewt und selektiert die Therapeut:innen persönlich
- Es werden in der Schweiz anerkannte psychologische Psychotherapeut:innen vermittelt
- Therapeut:innen sind selbstständig tätig und besitzen eine Schweizer Berufsanerkennung
- Alle verfügen über mehrere Jahre Praxiserfahrung
- Alle sind Teil eines Schweizerischen Branchenverbandes

[Hier lernst du mehr zu unserem Ansatz](https://aepsy.com/approach/)

**Wichtig:** Wenn du dich in einer lebensbedrohlichen Lage befindest oder dringend Hilfe benötigst, nutze diese Seite nicht. Kontaktiere bitte umgehend diese Institutionen: [Notfall](https://www.ch.ch/de/notfallnummern-erste-hilfe/)`,
    },
    {
        isMarkdown: true,
        question: 'Was ist eine Psychotherapie und was sind die Vorteile?',
        answer: `Es gibt Momente im Leben in denen die mentalen Belastungen so zunehmen, dass es guttut mit Therapeut:innen darüber zu reden. Im Verlauf einer Psychotherapie erhält man wertvolle Hilfestellungen. Diese werden gemeinsam mit der Psychotherapeutin oder dem Psychotherapeuten erarbeitet und wirken nachhaltig zur Selbsthilfe.

Der Nutzen einer Psychotherapie ist **wissenschaftlich belegt:**

- Nachhaltige Hilfe zur Selbsthilfe
- Steigerung des Wohlbefindens
- Rückgang von Beschwerden
- Verringerung des Rückfallrisikos

Diese sind nur einige der klinisch bedeutsamen Veränderungen. Weiter fördert Psychotherapie die persönliche und soziale Kompetenz, wodurch die psychische Belastbarkeit gesteigert und ein besserer Umgang mit verschiedensten Stressoren erlernt wird.`,
    },
    {
        isMarkdown: true,
        question: 'Was machen Psychotherapeut:innen?',
        answer: `Der Beruf der Psychotherapeut:innen kommt anderen Expertenberufen wie Anwälten, Ärzten oder Ingenieuren gleich. Menschen, die mental belastet sind, eine Herausforderung im Leben haben oder einfach einmal mit einer/einem Spezialist:in über ihre Probleme reden möchten, sind in einer Psychotherapie (od. psychologischen Coaching) richtig.

Um den geschützten Titel 'Psychotherapeut:in' zu erlangen braucht es bis zu elf Jahre Ausbildung. Nach einem Psychologiestudium wird zusätzlich ein mehrjähriges Studium für den Fachtitel zum/ zur Psychotherapeut:in angehängt. Psychotherapeut:innen sind Expert:innen für die menschliche Psyche.

[Zu unserem Ansatz](https://aepsy.com/approach/)`,
    },
    {
        isMarkdown: true,
        question:
            'Wie unterscheiden sich Psycholog:innen, Psychotherapeut:innen und Psychiater:innen? ',
        answer: `- **Psycholog:innen** haben ein Hochschulstudium in Psychologie abgeschlossen. Sie beschäftigen sich damit, menschliches und Verhalten zu beschreiben, zu erklären, vorherzusagen und zu ändern. Dies beinhaltet nicht zwingend eine Psychotherapieausbildung.
- **Psychotherapeut:innen** sind Psychologen, welche zusätzlich zu Ihrem Studium eine psychotherapeutische Spezialausbildung abgeschlossen haben. Sie sind dazu befähigt, Menschen mit psychischen Belastungen oder Krankheitsbildern zu behandeln.
- **Psychiater:innen** haben im Gegensatz dazu ein Medizinstudium abgeschlossen, und danach eine Facharztausbildung und psychotherapeutische Zusatzausbildung durchlaufen. Sie sind dazu berechtigt, Medikamente zu verschreiben.

Unabhängig vom Ausbildungsweg werden die **Methoden der Psychotherapie sowohl von Psychotherapeut:innen als auch von Psychiater:innen gleichermassen angewandt.** Die Behandlung ist somit unabhängig von der psychologischen oder medizinischen Grundausbildung.`,
    },
];

const FAQ_THERAPY_EN = [
    {
        isMarkdown: true,
        question: 'Why book a free 10min intro call first?',
        answer: `Aepsy **books you a free 10min intro** with the therapists before the therapy starts. Here are the reasons why:

- You **get to know the therapists first** before you start therapy.
- Time slots for the intros are **rashly available** even if the therapist is busy
- Problems are discussed and **ensured that the therapy fits**.
- Final **appointment setting** is done together on the phone
- Final questions about billing are clarified
- Advice on whether **an in-person meeting or online therapy** is a better fit`,
    },
    {
        isMarkdown: true,
        question: 'What will be discussed in the free 10min intro call?',
        answer: `The design of the 10min intro is left to the therapist:s discretion. Basically, however, the following aspects are discussed:

- Reference to the **personal challenge** (e.g. life circumstances).
- Discussion of the therapist's **specialties**.
- Discussion of the **procedure**.
- Agreement whether **online therapy or an on-site meeting**.
- Questions about **cost coverage**
- **agreement on the joint appointment**
`,
    },
    {
        isMarkdown: true,
        question: 'How does the therapist selection work at Aepsy?',
        answer: `
1. click on *[Start selection now](https://aepsy.com/buchen/f1/)* 
2. answer some questions about your needs
3. the Aepsy system evaluates suitable therapists
4. a handpicked selection and the "Aepsy match" is presented
5. compare the suggested therapists
6. make a choice and book directly your 10min intro call
7. get to know therapists and choose who suits you best
8. pay easily online, session by session, as it suits you
`,
    },
    {
        isMarkdown: true,
        question: `What is meant by "online therapy" or "on-site"?`,
        answer: `Our therapists offer online counseling and on-site meetings. It is also possible to combine the methods. 

- Online counseling can easily be done from home. The effectiveness of online interventions has been scientifically confirmed.
- The therapy rooms are listed on the therapist's profile. The desired location can be selected in the previous selection.
- Combinations are also possible. For example, it is possible to first meet the therapist in person and then conduct conversations online.

Start your selection here: *[Start selection now](https://aepsy.com/buchen/f1)*`,
    },
    {
        isMarkdown: true,
        question: 'How much do the therapy sessions cost?',
        answer: `10min-Intro-Call:

**Free**.

Therapy session:

**from CHF 170 / h** 

Online therapy session:

**from CHF 160 / h**

- Aepsy connects independent psychotherapists and acts as an intermediary platform.
- All therapists follow their own pricing strategies
- The prices are listed on the profile page of the therapists.

Here you can find the personal profiles of the therapists: [Find therapist now](http://www.aepsy.com/buchen/f1)`,
    },
    {
        isMarkdown: true,
        question: 'What is the payment process?',
        answer: `
- Intros are booked / performed free of charge and without specifying the payment method.
- Follow-up sessions are **simly paid online**
- You choose the appropriate therapist(s) and **pay for the sessions before**.

Currently, payments can be made with all major credit cards as well as TWINT `,
    },
    ,
    {
        isMarkdown: true,
        question: 'Will the costs be covered by my insurance?',
        answer: `- Psychotherapists listed at Aepsy offer places for self-payers / partial coverage supplementary insurance.
- No diagnosis/doctor's orders are required (model for basic insurance)
- Cost coverage varies from insurance to insurance
- The payment takes place online via Aepsy
- The receipt for the settlement with the supplementary insurance is simply generated as a PDF.
        
Here you can also find a list of the largest insurances with the respective supplementary insurances including the cost absorption: [Übernahme Therapiekosten Zusatzversicherung](https://aepsy.com/insurance-checker/) 
        
Here you can also find out how the system for the assumption of costs for psychotherapy works in Switzerland: [Delegated psychotherapy vs. independent psychotherapy](https://aepsy.com/de/blog/versicherung-kosten-psychotherapie/)
        
There are no psychotherapies that are covered by the basic insurance (so-called delegated psychotherapy) and require a doctor's order.`,
    },
    {
        isMarkdown: true,
        question: 'What makes the therapists at Aepsy special?',
        answer: `
- Aepsy interviews and selects the therapists personally.
- Psychological psychotherapists who are recognized in Switzerland are placed with us.
- Therapists are self-employed and have a Swiss professional license.
- All have several years of practical experience
- All are part of a Swiss professional association

[Learn more about our approach here](https://aepsy.com/approach/)

**Important:** If you are in a life-threatening situation or need urgent help, do not use this site. Please contact these institutions immediately: [emergency](https://www.ch.ch/de/notfallnummern-erste-hilfe/)
`,
    },
    {
        isMarkdown: true,
        question: 'What is psychotherapy and what are the benefits?',
        answer: `There are times in life when mental stress increases so much that it is good to talk about it with a therapist. In the course of psychotherapy one receives valuable assistance. These are worked out together with the psychotherapist and have a lasting effect on self-help.

The benefits of psychotherapy are **scientifically proven:**

- Sustainable help for self-help
- Increase of the well-being
- Decrease of complaints
- Reduction of the risk of relapse

These are just a few of the clinically significant changes. Further, psychotherapy promotes personal and social skills, increasing psychological resilience and learning to cope better with a wide variety of stressors`,
    },
    {
        isMarkdown: true,
        question: 'What do psychotherapists do?',
        answer: `The profession of psychotherapists is equal to other expert professions such as lawyers, doctors or engineers. People who are mentally burdened, have a challenge in life or just want to talk to a specialist about their problems are right in a psychotherapy (or psychological coaching).

It takes up to eleven years of training to obtain the protected title of psychotherapist. After a psychology degree, an additional study of several years is added for the specialized title of psychotherapist. Psychotherapists are experts in the human psyche.

[On our approach](https://aepsy.com/approach/)`,
    },
    {
        isMarkdown: true,
        question:
            'How do psychologists, psychotherapists, and psychiatrists differ? ',
        answer: `- **Psychologists** have a university degree in psychology. They deal with describing, explaining, predicting and changing human and behavior. This does not necessarily include psychotherapy training.
- **Psychotherapists** are psychologists who have completed specialized psychotherapeutic training in addition to their studies. They are qualified to treat people with psychological stress or disease patterns.
- **Psychiatrists**, on the other hand, have completed a medical degree, followed by a residency and additional psychotherapeutic training. They are authorized to prescribe medication.

Regardless of the training path, the **methods of psychotherapy are applied equally by both psychotherapists and psychiatrists**, so treatment is independent of basic psychological or medical training,`,
    },
];

const FAQ_THERAPY_FR = [
    {
        isMarkdown: true,
        question:
            "Pourquoi réserver d'abord un appel d'introduction gratuit de 10 minutes ?",
        answer: `Aepsy **réserve une intro** gratuite de 10 minutes avec les thérapeutes avant le début de la thérapie. Voici les raisons :

- On **apprend d'abord à connaître les thérapeutes** avant de commencer une thérapie
- Les plages horaires pour les intros sont également disponibles **rapidement** pour les thérapeutes occupés
- Les problèmes sont discutés et **on s'assure que la thérapie convient**
- La **fixation finale du rendez-vous** est effectuée en commun par téléphone
- Les dernières questions concernant la facturation sont clarifiées
- Consultation pour savoir si **une rencontre personnelle ou une thérapie en ligne** convient mieux`,
    },
    {
        isMarkdown: true,
        question:
            "De quoi discute-t-on lors de l'appel d'intro gratuit de 10min ?",
        answer: `La conception de l'intro de 10 minutes est laissée à l'appréciation des thérapeutes. Mais en principe, les aspects suivants sont abordés :

- Référence au **défi personnel** (par ex. conditions de vie)
- Discussion sur les **spécialités** des thérapeutes
- Discussion de la **procédure**
- Accord sur une **thérapie en ligne ou une rencontre sur place**
- Questions sur la **prise en charge des frais**
- **accord sur le rendez-vous commun**`,
    },
    {
        isMarkdown: true,
        question:
            'Comment fonctionne la sélection des thérapeutes chez Aepsy ?',
        answer: `1. clique sur *[Commencer la sélection](https://aepsy.com/buchen/f1/)* 
2. Réponds à quelques questions sur tes besoins
3. Le système Aepsy évalue les thérapeutes appropriés
4. Une sélection triée sur le volet et le "match Aepsy" sont présentés
5. Compare les thérapeutes proposés
6. Faites votre choix et réservez directement votre appel d'introduction de 10 minutes
7. Rencontre des thérapeutes et choisis celui qui te convient le mieux
8. Paye en ligne de manière simple, séance par séance, comme tu le souhaites`,
    },
    {
        isMarkdown: true,
        question: 'Qu\'entend-on par "thérapie en ligne" ou "sur place" ?',
        answer: `Nos thérapeutes proposent des consultations en ligne et des entretiens dans les locaux du cabinet. Il est également possible de combiner les méthodes 

- Les consultations en ligne peuvent être facilement effectuées à domicile. L'efficacité des interventions en ligne a été scientifiquement confirmée.
- Les locaux de thérapie sont indiqués sur le profil des thérapeutes. Dans la sélection précédente, il est possible de choisir la localité souhaitée.
- Des combinaisons sont également possibles. Il est par exemple possible de faire d'abord connaissance avec les thérapeutes en personne, puis de mener des entretiens en ligne.

Commence ta sélection ici : *[Commencer la sélection maintenant](https://aepsy.com/buchen/f1)*`,
    },
    {
        isMarkdown: true,
        question: 'Combien coûtent les séances de thérapie ?',
        answer: `Call d'intro de 10min :

**gratuit**

Séance de thérapie :

**à partir de CHF 170 / h** 

Séance de thérapie en ligne :

**à partir de CHF 160 / h**

- Aepsy met en relation des psychothérapeutes indépendants et agit en tant que plateforme de mise en relation
- Tous les thérapeutes suivent leur propre stratégie de prix
- Les prix sont indiqués sur la page de profil des thérapeutes

Ici, tu peux accéder aux profils personnels des thérapeutes : [Trouver un(e) thérapeute maintenant](http://www.aepsy.com/buchen/f1)`,
    },
    {
        isMarkdown: true,
        question: 'Comment se déroule le paiement ?',
        answer: `- Les intros sont réservées / effectuées gratuitement et sans indication du mode de paiement
- Les séances suivantes sont **payées en ligne** **simplement
- Tu choisis le/la thérapeute approprié(e) et **paie les séances avant de**

Actuellement, les paiements peuvent être effectués avec toutes les cartes de crédit courantes ainsi qu'avec Twint.`,
    },
    {
        isMarkdown: true,
        question: 'Les frais sont-ils pris en charge par mon assurance ?',
        answer: `- Les psychothérapeutes mentionnés sur Aepsy proposent des places pour les personnes payant elles-mêmes leur traitement / couverture partielle par l'assurance complémentaire.
- Aucun diagnostic/ordre médical n'est requis (modèle pour l'assurance de base).
- La prise en charge des coûts varie d'une assurance à l'autre.
- Le paiement s'effectue en ligne via Aepsy.
- Le justificatif pour le décompte avec l'assurance complémentaire est simplement généré sous forme de PDF.
        
Tu trouveras ici une liste des principales assurances avec les assurances complémentaires correspondantes, y compris la prise en charge des coûts : [Prise en charge des frais de thérapie assurance complémentaire](https://aepsy.com/insurance-checker/). 
        
Ici, tu peux en outre apprendre comment fonctionne le système de prise en charge des coûts de la psychothérapie en Suisse : [Psychothérapie déléguée vs. psychothérapie indépendante](https://aepsy.com/en/blog/versicherung-kosten-psychotherapie/)
        
Les psychothérapies prises en charge par l'assurance de base (appelées psychothérapies ordonnées) et nécessitant une prescription médicale ne sont pas enseignées.`,
    },
    {
        isMarkdown: true,
        question: "Qu'est-ce qui rend les thérapeutes d'Aepsy si spéciaux ? ",
        answer: `- Aepsy interviewe et sélectionne personnellement les thérapeutes
- Des psychothérapeutes psychologues reconnus en Suisse sont mis à disposition
- Les thérapeutes travaillent de manière indépendante et possèdent une reconnaissance professionnelle suisse
- Tous disposent de plusieurs années d'expérience pratique
- Tous font partie d'une association professionnelle suisse

[Pour en savoir plus sur notre approche](https://aepsy.com/approach/)

**Important:** Si tu te trouves dans une situation où ta vie est en danger ou si tu as besoin d'une aide urgente, n'utilise pas ce site. Contacte immédiatement ces institutions : [Urgence](https://www.ch.ch/de/notfallnummern-erste-hilfe/)`,
    },
    {
        isMarkdown: true,
        question:
            "Qu'est-ce qu'une psychothérapie et quels en sont les avantages ?",
        answer: `Il y a des moments dans la vie où le stress mental augmente tellement qu'il est bon d'en parler avec un thérapeute. Au cours d'une psychothérapie, on reçoit une aide précieuse. Celles-ci sont élaborées avec le psychothérapeute et ont un effet durable sur l'auto-assistance.

L'utilité d'une psychothérapie est **scientifiquement prouvée:**

- Aide durable à l'auto-assistance
- Augmentation du bien-être
- Diminution des troubles
- Diminution du risque de rechute

Ce ne sont là que quelques-uns des changements cliniquement significatifs. En outre, la psychothérapie favorise les compétences personnelles et sociales, ce qui permet d'augmenter la résistance psychique et d'apprendre à mieux gérer les facteurs de stress les plus divers.`,
    },
    {
        isMarkdown: true,
        question: 'Que font les psychothérapeutes ?',
        answer: `La profession de psychothérapeute est comparable à d'autres professions d'experts comme les avocats, les médecins ou les ingénieurs. Les personnes qui sont mentalement stressées, qui ont un défi à relever dans leur vie ou qui souhaitent simplement parler de leurs problèmes avec un(e) spécialiste sont à la bonne place dans une psychothérapie (ou un coaching psychologique).

Pour obtenir le titre protégé de "psychothérapeute", il faut jusqu'à onze ans de formation. Après des études de psychologie, on ajoute plusieurs années d'études pour obtenir le titre de psychothérapeute. Les psychothérapeutes sont des experts de la psyché humaine.

[A propos de notre approche](https://aepsy.com/approach/)`,
    },
    {
        isMarkdown: true,
        question:
            'En quoi les psychologues, les psychothérapeutes et les psychiatres sont-ils différents ? ',
        answer: `- **Les psychologues** ont suivi des études supérieures en psychologie. Ils s'occupent de décrire, d'expliquer, de prédire et de modifier le comportement humain. Cela n'implique pas nécessairement une formation en psychothérapie.
- **Les psychothérapeutes sont des psychologues qui, en plus de leurs études, ont suivi une formation spécialisée en psychothérapie. Ils sont habilités à traiter les personnes souffrant de troubles ou de maladies psychiques.
- **Les psychiatres, par contre, ont terminé leurs études de médecine et ont ensuite suivi une formation de médecin spécialiste et une formation psychothérapeutique complémentaire. Ils sont autorisés à prescrire des médicaments.

Indépendamment de la filière de formation, les méthodes de psychothérapie sont appliquées aussi bien par les psychothérapeutes que par les psychiatres.** Le traitement est donc indépendant de la formation psychologique ou médicale de base.`,
    },
];

const FAQ_THERAPY_IT = [
    {
        isMarkdown: true,
        question:
            'Perché prenotare prima una telefonata introduttiva gratuita di 10 minuti?',
        answer: `Su Aepsy **si prenota una telefonata introduttiva gratuita di 10 minuti** con i terapeuti:all'interno prima dell'inizio della terapia. Ecco i motivi per cui:

- Si **conoscono prima i terapeuti** prima di iniziare la terapia.
- Le fasce orarie per le introduzioni sono **abbastanza disponibili** anche con i terapeuti impegnati.
- Si discutono i problemi e ci si **assicura che la terapia sia adatta**.
- L'**appuntamento** finale viene preso insieme al telefono.
- Si chiariscono le ultime domande sulla fatturazione
- Si consiglia se **un incontro faccia a faccia o una terapia online** sia più adatta**`,
    },
    {
        isMarkdown: true,
        question: `Cosa verrà discusso nella telefonata introduttiva gratuita di 10 minuti`,
        answer: `La progettazione dell'introduzione di 10 minuti è lasciata al terapeuta. Fondamentalmente, tuttavia, vengono discussi i seguenti aspetti:

- riferimento alla **sfida personale** (ad esempio le circostanze della vita)
- Discussione delle **specialità** del terapeuta.
- Discussione della **procedura**.
- Accordo sulla scelta di una **terapia online o di un incontro in loco**.
- Domande sulla **copertura dei costi**
- Comune accordo sull'appuntamento**`,
    },
    {
        isMarkdown: true,
        question:
            'Come funziona il processo di selezione dei terapisti su Aepsy?',
        answer: `1. Cliccare su *[Inizia la selezione ora] (https://aepsy.com/buchen/f1/)*. 
2. rispondere ad alcune domande sulle proprie esigenze
3. il sistema di Aepsy valuta i terapisti idonei
4. viene presentata una selezione selezionata e il "match Aepsy".
5. confrontare i terapeuti proposti
6. fare una scelta e prenotare direttamente la telefonata introduttiva di 10 minuti
7. conoscere i terapeuti e scegliere quello più adatto alle proprie esigenze
8. pagare comodamente online, seduta per seduta, secondo le proprie esigenze`,
    },
    {
        isMarkdown: true,
        question: 'Cosa si intende per "terapia online" o "in loco"? ',
        answer: `I nostri terapeuti offrono consulenze online e incontri nelle sale di studio. È anche possibile combinare i due metodi. 

- La consulenza online può essere facilmente effettuata da casa. L'efficacia degli interventi online è stata confermata scientificamente.
- Le sale di terapia sono elencate nel profilo del terapeuta. La sede desiderata può essere scelta nella selezione precedente.
- Sono possibili anche combinazioni. Ad esempio, è possibile conoscere prima il terapeuta di persona e poi avere una conversazione online.

Inizia la tua selezione qui: *[Inizia la selezione ora](https://aepsy.com/buchen/f1)*`,
    },
    {
        isMarkdown: true,
        question: 'Quanto costano le sedute di terapia?',
        answer: `10min-Intro-Call:

**Gratuito**

Sessione di terapia:

**a partire da CHF 170 / h** 

Sessione di terapia online:

**a partire da CHF 160 / h**

- Aepsy si rivolge a psicoterapeuti indipendenti e funge da piattaforma di intermediazione.
- Tutti i terapeuti seguono una propria strategia tariffaria
- I prezzi sono indicati nella pagina del profilo del terapeuta.

Qui potete trovare i profili personali dei terapeuti: [Trova un terapeuta ora](http://www.aepsy.com/buchen/f1)`,
    },
    {
        isMarkdown: true,
        question: 'Come funziona il pagamento?',
        answer: `- Le sedute introduttive vengono prenotate/eseguite gratuitamente e senza specificare il metodo di pagamento.
- Le sessioni di follow-up sono **semplicemente** online** **pagamento**.
- Si sceglie il terapeuta o i terapeuti appropriati e si **paga per le sedute prima**.

Attualmente, i pagamenti possono essere effettuati con tutte le principali carte di credito e con Twint`,
    },
    {
        isMarkdown: true,
        question: 'I costi sono coperti dalla mia assicurazione?',
        answer: `- I terapeuti sono riconosciuti dall'assicurazione complementare.
- Il pagamento dei costi della terapia avviene online tramite Aepsy.
- La ricevuta di pagamento con l'assicurazione complementare viene generata semplicemente in formato PDF.
- La copertura dei costi varia da assicurazione ad assicurazione
- La psicoterapia non è coperta dall'assicurazione di base

Qui potete trovare anche un elenco delle principali assicurazioni con le rispettive assicurazioni complementari che includono la copertura dei costi: [copertura dei costi di terapia assicurazione complementare](https://aepsy.com/liste-versicherung-kosten-psychotherapie/)

Se la vostra assicurazione non è presente nell'elenco, ciò non significa che i costi non saranno coperti. L'elenco è limitato alle maggiori compagnie assicurative della Svizzera. 

Qui potete anche scoprire come funziona il sistema di copertura dei costi della psicoterapia in Svizzera: [Psicoterapia delegata vs. psicoterapia indipendente](https://aepsy.com/blog/%C3%9Cbernehmen-Versicherung-in-der-Schweiz-die-Kosten-f%C3%BCr-die-Psychotherapie)

Non sono previste psicoterapie coperte dall'assicurazione di base (la cosiddetta psicoterapia delegata) che richiedono un certificato medico`,
    },
    {
        isMarkdown: true,
        question: 'Cosa rende speciali i terapeuti di Aepsy? ',
        answer: `- Aepsy intervista e seleziona personalmente i terapeuti.
- Gli psicologi psicoterapeuti riconosciuti in Svizzera vengono inseriti presso di noi.
- I terapeuti sono liberi professionisti e dispongono di una licenza professionale svizzera.
- Tutti hanno diversi anni di esperienza pratica
- Tutti fanno parte di un'associazione professionale svizzera

[Per saperne di più sul nostro approccio, cliccare qui](https://aepsy.com/approach/)

**Importante:** Se vi trovate in una situazione di pericolo di vita o avete bisogno di aiuto urgente, non utilizzate questo sito. Contattare immediatamente le seguenti istituzioni: [Emergenza](https://www.ch.ch/de/notfallnummern-erste-hilfe/)`,
    },
    {
        isMarkdown: true,
        question: "Cos'è la psicoterapia e quali sono i benefici?",
        answer: `Ci sono momenti nella vita in cui lo stress mentale aumenta a tal punto che è bene parlarne con un terapeuta. Nel corso della psicoterapia si riceve un valido supporto. Questi vengono elaborati insieme allo psicoterapeuta e hanno un effetto duraturo sull'auto-aiuto.

I benefici della psicoterapia sono stati **scientificamente provati**.

- Un aiuto duraturo per l'auto-aiuto
- Aumento del benessere
- Diminuzione dei disturbi
- Riduzione del rischio di ricaduta

Questi sono solo alcuni dei cambiamenti clinicamente significativi. Inoltre, la psicoterapia promuove la competenza personale e sociale, che aumenta la resilienza psicologica e aiuta a imparare a gestire meglio un'ampia gamma di fattori di stress`,
    },
    {
        isMarkdown: true,
        question: 'Cosa fanno gli psicoterapeuti?',
        answer: `La professione di psicoterapeuta è simile ad altre professioni di esperti come avvocati, medici o ingegneri. Le persone che hanno un peso mentale, una sfida nella vita o semplicemente vogliono parlare dei loro problemi con uno specialista sono nel posto giusto per la psicoterapia (o coaching psicologico).

Sono necessari fino a undici anni di formazione per ottenere il titolo protetto di "psicoterapeuta". Dopo la laurea in psicologia, si aggiunge un ulteriore corso di studi della durata di alcuni anni per ottenere il titolo specialistico di psicoterapeuta. Gli psicoterapeuti sono esperti della psiche umana.

[Sul nostro approccio](https://aepsy.com/approach/)`,
    },
    {
        isMarkdown: true,
        question:
            'In cosa differiscono psicologi, psicoterapeuti e psichiatri? ',
        answer: `- **Gli psicologi** hanno una laurea in psicologia. Si occupano di descrivere, spiegare, prevedere e modificare il comportamento umano. Ciò non implica necessariamente una formazione in psicoterapia.
- Gli psicoterapeuti** sono psicologi che hanno completato una formazione psicoterapeutica specializzata in aggiunta alla loro laurea. Sono qualificati per trattare persone con stress o malattie psicologiche.
- Gli psichiatri**, invece, hanno conseguito una laurea in medicina e poi hanno seguito una formazione specialistica e una formazione psicoterapeutica aggiuntiva. Sono autorizzati a prescrivere farmaci.

Indipendentemente dal percorso formativo, i **metodi della psicoterapia sono applicati allo stesso modo sia dagli psicoterapeuti che dagli psichiatri**, quindi il trattamento è indipendente dalla formazione psicologica o medica di base`,
    },
];

const FAQ_THERAPY = {
    de: FAQ_THERAPY_DE,
    en: FAQ_THERAPY_EN,
    fr: FAQ_THERAPY_FR,
    it: FAQ_THERAPY_IT,
};

export default FAQ_THERAPY;
