import React from 'react';
import Divider from '@atoms/Divider/Divider';
import Module from '@atoms/Module/Module';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';

export default function CoachVsTherapyCard({
    image,
    title,
    subtitle,
    cta,
    children,
}) {
    return (
        <div className="CoachVsTherapyCard">
            <Module highlighted padding="l" radius="l">
                <div className="CoachVsTherapyCard--image">{image}</div>

                <Title size="l" noMargin>
                    {title}
                </Title>
                <Text size="m" theme="highlight">
                    {subtitle}
                </Text>

                <Divider spacing="xxs" invisible />

                <Text tag="div">{children}</Text>

                <Divider spacing="xs" invisible />

                <div className="g_center">{cta}</div>
            </Module>
        </div>
    );
}
