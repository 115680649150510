import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProviderStatementSlide from './ProviderStatementSlide';
import './ProviderStatementSlider.scss';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

interface Props {
    providerType: 'coach' | 'therapist';
}

const ProviderStatementSlider: React.FC<Props> = ({ providerType }) => {
    const className = ['ProviderStatementSlider']
        .join(' ')
        .replace(/\s{2,}/g, ' ');

    const { t } = useTranslation();

    function renderCoaches() {
        return (
            <>
                <SwiperSlide>
                    <ProviderStatementSlide
                        image="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/karin-hammerfald-aepsy-portrait.jpg"
                        audio="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/karin-hammerfald-about-audio.mp3"
                        quote={t('provider.coach.statement.one.quote')}
                        providerName="Dr. Karin Hammerfald"
                        providerSubtitle={t(
                            'provider.coach.statement.one.subtitle'
                        )}
                    />
                </SwiperSlide>

                <SwiperSlide>
                    <ProviderStatementSlide
                        image="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/romina-reginold-aepsy-portrait.jpg"
                        audio="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/romina-r-audio-presentation.mp3"
                        quote={t('provider.coach.statement.two.quote')}
                        providerName="Romina Reginold"
                        providerSubtitle={t(
                            'provider.coach.statement.two.subtitle'
                        )}
                    />
                </SwiperSlide>

                <SwiperSlide>
                    <ProviderStatementSlide
                        image="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/martina-holzach-aepsy-portrait.jpg"
                        audio="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/martina-h-audio-presentation.mp3"
                        quote={t('provider.coach.statement.three.quote')}
                        providerName="Martina Holzach"
                        providerSubtitle={t(
                            'provider.coach.statement.three.subtitle'
                        )}
                    />
                </SwiperSlide>
            </>
        );
    }

    function renderTherapists() {
        return (
            <>
                <SwiperSlide>
                    <ProviderStatementSlide
                        image="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/manuela-wingeier-aepsy-portrait.jpg"
                        audio="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/manuela-wingeier-audio-presentation.mp3"
                        quote={t('provider.therapist.statement.one.quote')}
                        providerName="Manuela Wingeier"
                        providerSubtitle={t(
                            'provider.therapist.statement.one.subtitle'
                        )}
                    />
                </SwiperSlide>

                <SwiperSlide>
                    <ProviderStatementSlide
                        image="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/pauline-mueller-debus-aepsy-portrait.jpg"
                        audio="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/pauline-md-audio-presentation.mp3"
                        quote={t('provider.therapist.statement.two.quote')}
                        providerName="Pauline Müller-Debus"
                        providerSubtitle={t(
                            'provider.therapist.statement.two.subtitle'
                        )}
                    />
                </SwiperSlide>

                <SwiperSlide>
                    <ProviderStatementSlide
                        image="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/annette-fueglister-aepsy-portrait.jpg"
                        audio="https://storage.googleapis.com/aepsy-website.appspot.com/flamelink/media/annette-fueglister-about-audio.mp3"
                        quote={t('provider.therapist.statement.three.quote')}
                        providerName="Annette Füglister"
                        providerSubtitle={t(
                            'provider.therapist.statement.three.subtitle'
                        )}
                    />
                </SwiperSlide>
            </>
        );
    }

    return (
        <div className={className}>
            <Swiper
                pagination={{
                    el: '.ProviderStatementSlider--pagination',
                    clickable: true,
                    renderBullet: (index, className) => {
                        return '<span class="' + className + '"></span>';
                    },
                }}
                spaceBetween={50}
                resistanceRatio={0.9}
                speed={500}
                modules={[Navigation, Pagination, Autoplay]}
            >
                {providerType === 'coach'
                    ? renderCoaches()
                    : renderTherapists()}

                <div className="ProviderStatementSlider--pagination"></div>
            </Swiper>
        </div>
    );
};

export default ProviderStatementSlider;
