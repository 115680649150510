import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@atoms/Button/Button';
import SideSection from '@components/SideSection/SideSection';
import { useLocale } from '@hooks';

export default function TherapyKeySteps() {
    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();
    const image_data = useStaticQuery(graphql`
        query {
            feature_01: file(
                relativePath: { eq: "experience/aepsy-experience-7.png" }
            ) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            feature_02: file(
                relativePath: { eq: "experience/aepsy-experience-15.png" }
            ) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            feature_03: file(
                relativePath: { eq: "experience/aepsy-experience-11.png" }
            ) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            feature_04: file(
                relativePath: { eq: "experience/aepsy-experience-17.png" }
            ) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <>
            <SideSection
                title={t('therapy.how.does.it.work.step.one.title')}
                text={t('therapy.how.does.it.work.step.one.text')}
                visual={
                    <Img
                        fluid={image_data.feature_01.childImageSharp.fluid}
                        alt={
                            t('therapy.how.does.it.work.step.one.title') +
                            ' - Visual'
                        }
                    />
                }
                link={
                    <a
                        href={getAppLinkWithCurrentLang(
                            process.env.GATSBY_START_THERAPY_FUNNEL_URL
                        )}
                    >
                        <Button
                            variant="inline"
                            theme="highlighted"
                            label={t('find.pyschologist.cta.label')}
                        />
                    </a>
                }
            />
            <SideSection
                title={t('therapy.how.does.it.work.step.two.title')}
                text={t('therapy.how.does.it.work.step.two.text')}
                visual={
                    <Img
                        fluid={image_data.feature_02.childImageSharp.fluid}
                        alt={
                            t('therapy.how.does.it.work.step.two.title') +
                            ' - Visual'
                        }
                    />
                }
            />
            <SideSection
                title={t('therapy.how.does.it.work.step.three.title')}
                text={t('therapy.how.does.it.work.step.three.text')}
                visual={
                    <Img
                        fluid={image_data.feature_03.childImageSharp.fluid}
                        alt={
                            t('therapy.how.does.it.work.step.three.title') +
                            ' - Visual'
                        }
                    />
                }
            />
            <SideSection
                title={t('therapy.how.does.it.work.step.four.title')}
                text={t('therapy.how.does.it.work.step.four.text')}
                visual={
                    <Img
                        fluid={image_data.feature_04.childImageSharp.fluid}
                        alt={
                            t('therapy.how.does.it.work.step.four.title') +
                            ' - Visual'
                        }
                    />
                }
            />
        </>
    );
}
