import React from 'react';
import { AiTwotoneStar } from 'react-icons/ai';

interface Props {
    total?: number;
}

const Rating: React.FC<Props> = ({ total = 5 }) => {
    return (
        <div className="g_1_1">
            {[...Array(total)].map(() => (
                <AiTwotoneStar fill="#F2C94C" />
            ))}
        </div>
    );
};

export default Rating;
